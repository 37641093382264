import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";

function Term() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div id="main">
            <h1>
              Site Policy <span>利用規約 </span>{" "}
            </h1>
          </div>
          <div className="note">
            <div className="txt22">
              <p>
                <span>最終更新日：平成21年4月1日</span>
                公益財団法人日本ゴルフ協会
              </p>
            </div>
            <div className="img">
              <img
                decoding="async"
                src="	https://www.jga.or.jp/data/wp-content/uploads/2023/10/logo.gif"
              />
            </div>
          </div>
          <p style={{ marginBottom: "60px" }}>
            公益財団法人日本ゴルフ協会（以下「当協会」）のホームページ（以下「当サイト」）をご利用頂くには、以下の利用規約をご一読頂き、ご同意頂く必要があります。従って利用者が当サイトをご利用頂くことで以下の利用規約にご同意頂いたものとみなしますのでご留意下さい。
          </p>
          <div className="inner">
            <h2 className="wp-block-heading">
              1 . 利用規約について<gwmw></gwmw>
              <gwmw></gwmw>
            </h2>

            <p>
              当協会が定める利用規約（以下「当利用規約」）は、了承を得ることなく内容を変更することがあります。
            </p>

            <h2 className="wp-block-heading">
              2 . 当サイトについて<gwmw></gwmw>
              <gwmw></gwmw>
            </h2>

            <p>
              当協会は予告をせずに、当サイトの情報の更新・修正・削除等をすることがあります。また、サイトの公開を一時中止・閉鎖等することがあります。これらにより利用者に生じるいかなる障害に関しても、当協会は一切の責任を負わないものとします。
            </p>

            <h2 className="wp-block-heading">
              3 . 当サイトから配信される情報について
              <gwmw></gwmw>
            </h2>

            <p>
              当協会は当サイトに掲載する情報に関し、細心の注意を払っております。しかしながら、全ての情報の正確性、安全性等に関し、一切の保証をするものではありません。また、当サイトを利用することにより発生するいかなる障害・損失・トラブル等に関して、いかなる理由に関わらず、当協会は一切の責任を負わないものとします。
            </p>

            <h2 className="wp-block-heading">
              4 . 著作権などについて<gwmw></gwmw>
            </h2>

            <p>
              当サイトに掲載されている全ての内容に関する権利は、当協会に既存するか、ライセンスに基づいて使用しております。従って当サイトに掲載されている一部または全部について、無断使用・複製することを固く禁止いたします。また、当サイト上のコンテンツは著作権法により保護されています。サイト利用者が著作権者の権利を侵害した場合には、著作権法により罰せられますのでご注意下さい。
            </p>

            <h2 className="wp-block-heading">
              5 . 当サイトへのリンクについて<gwmw></gwmw>
            </h2>

            <p>
              当サイトにリンクを張る場合には、必ず当協会までご連絡下さい。その際、営利を目的とした場合や当協会の事業等を害する恐れがある場合等、サイトの内容によっては、リンクを張ることをお断りする場合があります。
            </p>

            <h2 className="wp-block-heading">
              6 . 当サイトからのリンクについて
              <gwmw></gwmw>
            </h2>

            <p>
              当サイトから当協会以外のサイトにリンクを張っている場合、サイト利用者の皆様への便宜のためにご提供しているにすぎません。また、リンク先のサイトは当協会の管理下にあるものではありません。利用者がリンク先のサイトをご利用になったことにより、いかなる障害が発生いたしましても、当協会は一切の責任を負わないものとします。
            </p>

            <h2 className="wp-block-heading">
              7 . 免責事項について<gwmw></gwmw>
            </h2>

            <p>
              当協会は、利用者および第三者に対し、当サイトを通じて提供する全ての情報に関する適法性、完全性、正確性、有用性、第三者の権利侵害がないこと等、当サイトの利用・接続環境等に関していかなる保障も行わないものとします。また、未成年者が当サイトを利用する場合、親権者は当サイトや当サイト内で公開されるコンテンツが未成年者にとって適切かどうか判断する責を負うものとします。利用者が当サイトの利用に際し第三者に対して損害を与えた場合、利用者の責任と費用をもって解決し、当協会に損害を与えることのないものとします。当協会は当サイトの利用により発生した利用者の損害に対し、いかなる責任も負わないものとし、一切の損害賠償をする義務は負わないものとします。また、利用者が本規約に違反したことにより当協会に損害を与えた場合、当協会は当該利用者に対して損害の賠償を請求できるものとします。
            </p>

            <ul className="indent wp-block-list">
              <li>
                (1)下記の事項により利用者または第三者が破った損害について、当協会はその責任を負わないものとします。
                <ul className="wp-block-list">
                  <li>
                    ・通信機器、通信回線、コンピュータ等のシステム機器等の障害または瑕疵等により当サイトの提供が中断もしくは遅延し、または誤送信もしくは欠陥が生じた場合
                  </li>

                  <li>
                    ・第三者による当サイトへの妨害、システムへの侵入、情報改変等により当サイトの提供が中断もしくは遅延し、または誤送信もしくは欠陥が生じた場合
                    <gwmw></gwmw>
                  </li>

                  <li>
                    ・当協会の推奨しない通信環境から接続したために当サイト内の情報を完全に取得できない場合、または機器等に障害・瑕疵が発生した場合
                    <gwmw></gwmw>
                    <gwmw></gwmw>
                  </li>

                  <li>
                    ・利用者から送信された情報が、当協会の故意もしくは重過失によらないシステム上の制限、エラー、内容の瑕疵により受信されない場合、または誤った情報が受信された場合
                    <gwmw></gwmw>
                  </li>

                  <li>
                    ・当協会に故意または重過失なくして、当サイトが提供する情報に誤送信、欠陥があった場合
                    <gwmw></gwmw>
                    <gwmw></gwmw>
                    <gwmw></gwmw>
                    <gwmw></gwmw>
                    <gwmw></gwmw>
                  </li>
                </ul>
              </li>

              <li>
                (2)当協会は、当サイトの利用に関して利用者が使用しているコンピュータ、通信機器、通信ソフト等のサポートは一切行いません。利用者と各種プロバイダーとの接続に関する苦情等についても、一切これを受け付けないものとします。
              </li>

              <li>
                (3)利用者は、当サイトの利用ならびに接続に関連して第三者からクレーム、損害賠償請求等を受けた場合には、自らの責任と負担において解決するものとし、当協会は解決に協力する義務を負わないものとします。また、当協会が第三者からクレーム、損害賠償請求等を受けた場合、その解決に関して必要な範囲で利用者に協力を求めることがあり、利用者はこれを拒否しないものとします。
              </li>
            </ul>

            <h2 className="wp-block-heading">
              8 . 本規約違反について<gwmw></gwmw>
            </h2>

            <p>
              サイト利用者の皆様には、当サイトをJGA公式ウェブサイトと認識し、合法的な目的にのみ利用することに同意して頂きます。また、以下の事項に該当する場合には、当協会は利用者に対して除名・法的措置を取らせて頂くことがあります。
            </p>

            <ul className="wp-block-list">
              <li>
                ・当協会が定める著作権規定に対して違反する行為また他者の著作権、商標権等の知的財産権を侵害する行為、またはその恐れのある行為があった場合
                <gwmw></gwmw>
              </li>

              <li>
                ・他者の財産、プライバシーもしくは肖像権を侵害する行為、またはその恐れのある行為があった場合
                <gwmw></gwmw>
              </li>

              <li>
                ・他者を差別もしくは誹謗中傷し、または他者の名誉もしくは信用を毀損する行為があった場合
                <gwmw></gwmw>
              </li>

              <li>
                ・当サイトの運営を妨害する行為があった場合
                <gwmw></gwmw>
              </li>

              <li>
                ・別の他人や組織などになりすましたり、人物、企業、グループあるいはその他の実体との関係を故意に詐称した場合
                <gwmw></gwmw>
              </li>

              <li>
                ・意図的に法律違反をした場合<gwmw></gwmw>
              </li>

              <li>
                ・上記各項目のいずれかに該当する行為（当該行為を他者が行っている場合を含む）を助長する目的の行為（リンクを張る等を含む）があった場合
                <gwmw></gwmw>
                <gwmw></gwmw>
                <gwmw></gwmw>
                <gwmw></gwmw>
                <gwmw></gwmw>
                <gwmw></gwmw>
                <gwmw></gwmw>
                <gwmw></gwmw>
                <gwmw></gwmw>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Term;
