import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/privacy.css";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle11">
          <b>■プライバシーポリシー</b>
          <p class="MsoNormal" align="right">
            施行日　2014年11月01日
          </p>
          <p class="MsoNormal" align="right">
            最終改定日　2023年11月28日
          </p>
          <br />
          株式会社グッドフェローズ（以下「当社」といいます）は、お客様の個人情報が重要なものであると認識し、プライバシーポリシー（以下「本ポリシー」といいます）に基づき適切な取扱いと保護に努めてまいります。
          <br />
          本ポリシーは、当社が運営するチケット販売サービス「Webket」（以下「本サービス」といいます）に関する利用規約とともに、本サービスの利用に適用されます。
          <br />
          <br />
          <b>第１条（個人情報）</b>
          <br />
          １．「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、①当該情報に含まれる氏名、生年月日、その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含む。）及び②個人識別符号が含まれるものを指します。
          <br />
          ２．「要配慮個人情報」とは、個人情報のうち、人種、信条、社会的身分、病歴、犯罪の経歴、犯罪により害を被った事実その他本人に対する不当な差別、偏見その他の不利益が生じないようにその取扱いに特に配慮を要するものとして政令で定める記述等が含まれるものを指します。身体障害、知的障害、精神障害等の障害があること、健康診断その他の検査の結果、保健指導、診療・調剤情報等も該当します。要配慮個人情報について、当社は、個人情報保護法第17条第2項各号所定の場合を除くほか、あらかじめお客様の同意を得ないで、取得したり、第三者に提供したりいたしません。
          <br />
          ３．本ポリシーにおいて使用される用語の定義は、本ポリシーにおいて特段の定めのある場合を除き、個人情報保護法その他法令の定義に従います。
          <br />
          <br />
          <b>第２条（取得する個人情報及び取得方法）</b>
          <br />
          １．当社は、利用目的の達成のために必要な範囲において、適法かつ適正な手段により個人情報を含むパーソナルデータ
          （本サービス利用に際しお客様が登録した会員情報、購入履歴等を含みます。以下「パーソナルデータ」といいます）を取得します。
          <br />
          パーソナルデータを取得する際には、利用目的をできる限り特定し、パーソナルデータにより識別されるお客様に通知、または公表します。
          <br />
          ２．当社が取得するパーソナルデータは、その取得方法に応じて、以下のとおりとなります。
          <br />
          　(1) お客様から直接取得する情報
          <br />
          　当社は、本サービスの利用、キャンペーンへのご応募、アンケートへの回答、電話や電子メールでのお問合せやご指摘などを通じて、お客様から直接、氏名、生年月日、住所、電話番号、メールアドレスなどのパーソナルデータを取得することがあります。
          <br />
          また、本サービスの利用にあたり、要配慮個人情報をお客様の同意を得てまたは法令に基づき取得することがあります。
          <br />
          　(2)
          アクセス時に取得する情報　当社は、お客様が当社のウェブサイトを閲覧する際、閲覧履歴、購買履歴、端末の種類、UserAgent等の端末識別子やOS、ブラウザの種類・設定に関する情報、IPアドレス、Cookie、リファラその他広告識別子等の情報を取得することがあります。
          <br />
          　(3) 外部サービスとの連携により取得する情報
          <br />
          　当社は、本サービスの利用にあたって、お客様が外部サービスとの連携を許可した場合、外部サービスでお客様が利用するID、その他外部サービスのプライバシー設定によりお客様が連携先に開示を認めた情報を当該外部サービスから取得します。
          <br />
          　(4) 当社の提携先からの取得
          <br />
          　当社は、お客様と当社の提携先（施設を含みます。以下、「提携先」といいます）との間でなされた契約等によって、提携先が取得したお客様のパーソナルデータを含む取引記録やご利用に関する情報を、提携先から取得することがあります。
          <br />
          <br />
          <b>第３条（利用目的）</b>
          <br />
          当社は、お客様のパーソナルデータを、取得した情報に応じ次の目的にのみ利用するものとします。
          <br />
          <table border="1" width="100%">
            <tbody>
              <tr style={{ background: "#f0f0ff" }}>
                <th width="113" valign="top">
                  取得する情報
                </th>
                <th width="765" valign="top">
                  利用目的
                </th>
              </tr>
              <tr>
                <td width="150" valign="top">
                  Webket会員情報
                </td>
                <td width="765" valign="top">
                  ・本サービスに関するお客様の会員登録の受付、本人確認及び認証、商品の提供、購入履歴の記録等の、本サービスの提供のため
                  <br />
                  ・本サービスの維持、保護または改善のため
                  <br />
                  ・本サービスに関する、電話、郵送、メール等によるご案内及びご連絡、アンケート及びメールマガジンの配信のため
                  <br />
                  ・本サービスへご登録された情報の訂正、削除等のため
                  <br />
                  ・施設またはイベントの休止、延期等が発生した際の連絡、あるいは施設またはイベントからの広告の配信のため
                  <br />
                  ・本サービスに関するご案内、あるいは当社より緊急または重要なお知らせの配信のため
                  <br />
                  ・本サービスの改善、もしくは新規商品、サービス開発等に必要なデータの分析のため
                  <br />
                  ・お客様からいただいたご意見、お問合せ等への対応のため
                  <br />
                  ・本サービスに関する当社の規約、ポリシー等に違反する行為に対する対応のため
                  <br />
                  ・本サービスに関する契約や法律に基づく権利の行使、請求等に関する対応のため
                  <br />
                </td>
              </tr>
              <tr>
                <td width="150" valign="top">
                  アクセス時に取得する情報
                </td>
                <td width="765" valign="top">
                  ・本サービスに関するお客様の会員登録の受付、本人確認及び認証、商品の提供、購入履歴の記録等の、本サービスの提供のため
                  <br />
                  ・本サービスの維持、保護または改善、もしくは新規サービス開発等に必要なデータの分析、トラフィック測定及び行動測定のため
                  <br />
                  ・お客様からいただいたご意見、お問合せ等への対応のため
                  <br />
                </td>
              </tr>
              <tr>
                <td width="150" valign="top">
                  外部サービスとの連携により取得する情報
                </td>
                <td width="765" valign="top">
                  ・本サービスに関するお客様の会員登録の受付、本人確認及び認証のため
                </td>
              </tr>
              <tr>
                <td width="150" valign="top">
                  当社の提携先からの取得
                </td>
                <td width="765" valign="top">
                  ・提携先からの調査依頼への返答のため
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <b>第４条（パーソナルデータの利用目的の遵守及び利用目的の変更）</b>
          <br />
          １．当社は、パーソナルデータを利用する際には、収集時に明らかにした利用目的の範囲を遵守します。
          <br />
          ２．当社は、利用目的が変更前と関連性を有すると合理的に認められる範囲内において、パーソナルデータの利用目的を変更することがあります。利用目的の変更を行った場合には、変更後の目的について、当社所定の方法により、お客様に通知するものとします。
          <br />
          <br />
          <b>第５条（第三者提供の制限）</b>
          <br />
          当社は、お客様のパーソナルデータの一部もしくは全部を、お客様の同意を得ないで、第三者（日本国外にある者を含みます。以下同じとします）に提供しません。但し、次の各号の場合は、この限りではありません。
          <br />
          　(1) 施設への提供
          <br />
          　本サービスでチケットを購入したお客様の会員情報及び購入情報を、①お客様が施設を利用される際の本人確認、購入番号の確認のため、②
          第３条各項の業務を行うため、③
          施設からのチケットの発送、またはお問合せ対応のため、施設へ提供する場合があります。
          <br />
          　(2) 提携する決済代行会社への提供
          <br />
          　当社は、当社が必要と判断した場合に限り、提携する決済代行会社（クレジットカード会社を含みます。以下「決済代行会社等」といいます）からの問合せに対し、お客様の会員情報及びお客様の購入履歴の全部または一部を提供する場合があります。
          <br />
          　(3) 当社の委託先
          <br />
          　当社は、利用目的の達成に必要な範囲内において、お客様のパーソナルデータの取り扱いの全部または一部を委託する場合があります。その場合、当社はその適格性を十分に審査し、パーソナルデータの安全管理が図られるよう、委託を受けた者に対する必要かつ適切な監督を行うこととします。
          <br />
          　(4) 国の機関もしくは地方公共団体またはその委託を受けた者
          <br />
          　国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合、お客様のパーソナルデータの全部または一部を提供する場合があります。
          <br />
          　(5)
          その他、個人情報の保護に関する法律、その他の法令等で認められる場合
          <br />
          　当該法令等で指定された者へ、お客様のパーソナルデータの全部または一部を提供する場合があります。
          <br />
          <br />
          <b>第６条（情報通信端末に関する情報及びCookieに関して）</b>
          <br />
          １．当社は、本人認証、サービスの利便性向上、お客様のお問合せ対応や、アクセス解析及び統計データの取得のため、情報通信端末情報（端末識別に関する情報、IPアドレス、リファラ等を含みます）及びCookie（機能性Cookie、分析Cookieを含みます。以下、情報通信端末情報とあわせて「Cookie等」といいます）を利用しております。
          <br />
          ２．本サービス上では、以下の他社が発行するCookie等を利用しております。その場合、他社に提供されるCookie等は、特定の個人を識別しない形で本サービスの利用に関する情報を収集しており、当該情報がお客様　を特定するために使用されることはありません。
          <br />
          <table border="1" width="100%">
            <tbody>
              <tr style={{ background: "#f0f0ff" }}>
                <th width="33%" valign="top">
                  会社名（サービス名）
                </th>
                <th valign="top">目的</th>
                <th valign="top">プライバシーポリシー</th>
              </tr>
              <tr>
                <td valign="top">
                  グーグル合同会社
                  <br />
                  （Googleアナリティクス）
                </td>
                <td width="765" valign="top">
                  本サービスの維持、保護または改善、もしくは新規サービス開発等に必要なデータの分析、トラフィック測定及び行動測定のため
                </td>
                <td width="765" valign="top">
                  <a
                    href="https://policies.google.com/privacy?hl=ja"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://policies.google.com/privacy?hl=ja
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          ３．ほとんどのブラウザは、Cookie等を有効とする設定がデフォルトになっています。お客様は以下のURLよりブラウザの設定を変更し、いつでもCookie等を無効にしたり、Cookie等の送信時にそれを表示したりすることが可能です。但し、Cookie等が無効にされた場合、本サービスが正しく機能せず、本サービスの一部または全部がご利用いただけない可能性があります。
          <br />
          <table border="1" width="100%" style={{ borderCollapse: "collapse" }}>
            <tbody>
              <tr>
                <td width="208" valign="top">
                  Microsoft Internet Explorer
                </td>
                <td width="661" valign="top">
                  <a
                    href="https://support.microsoft.com/ja-jp/topic/cookie-%E3%81%AE%E8%AA%AC%E6%98%8E-ad01aa7e-66c9-8ab2-7898-6652c100999d"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.microsoft.com/ja-jp/topic/cookie-%E3%81%AE%E8%AA%AC%E6%98%8E-ad01aa7e-66c9-8ab2-7898-6652c100999d
                  </a>
                </td>
              </tr>
              <tr>
                <td width="208" valign="top">
                  Google Chrome
                </td>
                <td width="661" valign="top">
                  <a
                    href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=ja"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=ja
                  </a>
                </td>
              </tr>
              <tr>
                <td width="208" valign="top">
                  Microsoft Edge
                </td>
                <td width="661" valign="top">
                  <a
                    href="https://support.microsoft.com/ja-jp/topic/cookie-%E3%81%AE%E8%AA%AC%E6%98%8E-ad01aa7e-66c9-8ab2-7898-6652c100999d"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.microsoft.com/ja-jp/topic/cookie-%E3%81%AE%E8%AA%AC%E6%98%8E-ad01aa7e-66c9-8ab2-7898-6652c100999d
                  </a>
                </td>
              </tr>
              <tr>
                <td width="208" valign="top">
                  Mozilla Firefox
                </td>
                <td width="661" valign="top">
                  <a
                    href="https://support.mozilla.org/ja/kb/clear-cookies-and-site-data-firefox"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.mozilla.org/ja/kb/clear-cookies-and-site-data-firefox
                  </a>
                </td>
              </tr>
              <tr>
                <td width="208" valign="top">
                  Safari
                </td>
                <td width="661" valign="top">
                  <a
                    href="https://support.apple.com/ja-jp/guide/safari/sfri11471/mac"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.apple.com/ja-jp/guide/safari/sfri11471/mac
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <b>第７条（安全管理措置）</b>
          <br />
          当社は、取得したお客様のパーソナルデータの適切な管理を行うため、次の安全管理措置を実施します。
          <br />
          　(1) 基本方針の策定
          <br />
          　パーソナルデータの取り扱いに関する社内規程を策定しています。
          <br />
          　(2) 組織的安全管理措置
          <br />
          　・パーソナルデータの取扱いに関する個人情報保護管理者を設置するとともに、個人情報保護法や社内規程に違反している事実または兆候を把握した場合の個人情報保護管理者への報告連絡体制を整備しています。
          <br />
          　・パーソナルデータの取扱状況について、定期的に内部監査を実施しています。
          <br />
          　(3) 人為的安全管理措置
          <br />
          　・パーソナルデータを含む情報の取扱いに関する留意事項について、社内の定期的な教育を実施しています。
          <br />
          　・パーソナルデータについての秘密保持に関する事項を社内規程に記載しています。
          <br />
          　(4) 物理的安全管理措置
          <br />
          　・パーソナルデータを取り扱う区域において、従業員の入退室管理及び持ち込む機器等の制限を行うとともに、権限を有しない者によるパーソナルデータの閲覧を防止する措置を実施しています。
          <br />
          　・パーソナルデータを取り扱う機器、電子媒体及び書類等の盗難または紛失等を防止するための措置を講じています。
          <br />
          　(5) 技術的安全管理措置
          <br />
          　・アクセス制御を実施して、取り扱うパーソナルデータのデータベースなどの範囲を限定しています。
          <br />
          　・パーソナルデータを取り扱う情報システムを外部からの不正アクセスまたは不正ソフトウェアから保護する仕組みを導入しています。
          <br />
          <br />
          <b>第８条（パーソナルデータの提供）</b>
          <br />
          パーソナルデータの提供は任意です。但し、パーソナルデータをご提供いただけない場合、利用目的に係る本サービスの提供ができない場合があります。
          <br />
          <br />
          <b>第９条（個人情報に関する法令等の遵守）</b>
          <br />
          当社は、お客様のパーソナルデータを取り扱う際に、本ポリシー及び個人情報の保護に関する法律等を遵守します。
          <br />
          <br />
          <b>第１０条（保存期間）</b>
          <br />
          １．当社が取得したお客様のパーソナルデータは、本サービスの提供のため、必要な期間に限り当社にて適切に保存します。具体的な保存期間は、パーソナルデータの収集・処理目的、パーソナルデータの性質、法律上または業務上のパーソナルデータ保持の必要性を考慮して、当社にて決定します。
          <br />
          ２．お客様は、当社に対し、パーソナルデータの削除をいつでも請求することができます。
          <br />
          <br />
          <b>第１１条（個人情報保護に関するお問合せ）</b>
          <br />
          当社は、お客様から、個人情報保護法の定めに基づき個人情報または第三者提供の記録の開示を求められたときは、お客様ご本人もしくはその代理人であることを確認の上で、遅滞なく情報を開示し、当該個人情報が存在しないときにはその旨を通知いたします。個人情報の開示につきましては、手数料1件あたり税込1,000円を頂戴しておりますので、あらかじめご了承ください。なお、その他個人情報に関するクレーム、削除請求等のお問合せにつきましても、下記お問合せ窓口にお問合せください。
          <br />
          <br />
          　(1) お問い合わせ窓口
          <br />
          　株式会社 グッドフェローズ　個人情報保護総合窓口
          <br />
          　電話：0422-27-8701(代表)　※月～金：9:30am～5:00pm
          <br />
          　FAX：0422-27-8702
          <br />
          　E-mail：free@wordhood.shop
          <br />
          　住所：〒180-0006　東京都武蔵野市中町1-15-5三鷹髙木ビル7F
          <br />
          <br />
          　(2) 個人情報開示等請求の手順
          <br />
          　① 前項のお問合せ窓口へ、個人情報開示等の請求の旨をお伝えください。
          <br />
          　②
          お問合せいただいたお客様へ、当社より「個人情報開示等請求書」及び手数料のお支払い方法のご案内を郵送もしくは電子メールにてお送りさせていただきます。
          <br />
          　③
          「個人情報開示等請求書」に必要事項を記入し、ご本人確認書類及び代理人確認書類を添付の上、個人情報保護総合窓口まで返送し、手数料をお支払いください。
          <br />
          　④ 当社より郵送もしくは電子メールにて回答させていただきます。
          <br />
          <br />
          <b>第１２条（パーソナルデータの訂正および利用の停止等）</b>
          <br />
          １．当社は、お客様から、パーソナルデータの内容が事実ではなく、訂正、追加、削除（以下、「訂正等」といいます）を求められたときは、お客様からのご請求であることを確認のうえ遅滞なく調査を行い、その結果に基づき、パーソナルデータの内容の訂正等を行い、その旨をお客様に通知します。
          <br />
          ２．当社は、お客様から、以下の各号の理由に基づきパーソナルデータの利用の停止または削除（以下「利用の停止等」という）を求められたときは、お客様からのご請求であることを確認のうえ遅滞なく調査を行い、その結果に基づき、パーソナルデータの利用の停止等を行い、その旨をお客様に通知します。
          <br />
          　(1)
          予め公表された利用目的の範囲を超えてパーソナルデータが取扱われている場合
          <br />
          　(2)
          偽り、その他の手段によりパーソナルデータが取得されたものである場合
          <br />
          　(3)
          違法または不当な行為を助長し、または誘発する恐れがある方法によりパーソナルデータを利用している場合
          <br />
          　(4) パーソナルデータを利用する必要がなくなった場合
          <br />
          　(5) パーソナルデータの漏えい、滅失、毀損等が生じた場合
          <br />
          　(6) その他本人の権利または正当な利益が害されるおそれがある場合
          <br />
          ３．個人情報保護法その他の法令により、当社が訂正等または利用の停止等の義務を負わない場合、本条１項、２項の定めは適用されません。
          <br />
          <br />
          <b>第１３条（本ポリシーの変更）</b>
          <br />
          当社は、法令その他本ポリシーに別段の定めのある事項を除いて、民法第548条の4の規定によりお客様の承諾なく、当社独自の判断で、本ポリシーを変更することがあります。なお、本ポリシーを変更する場合、2週間以上前に各施設の販売サイトにて、本ポリシーを変更する旨、変更内容、変更時期を通知し、変更時期以降にお客様が本サービスをご利用いただいた時点より効力を発するものとします。
          <br />
          <br />
          <br />
          <b>　【個人情報取り扱い事業者の名称】</b>
          <br />
          　　株式会社グッドフェローズ <br />
          　　代表取締役社長　　磯部　昌美
          <br />
          　　〒180-0006
          <br />
          　　　東京都武蔵野市中町1-15-5　三鷹髙木ビル7F
          <br />
          　　TEL：0422-27-8701(代表)
          <br />
          　　FAX：0422-27-8702
          <br />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Privacy;
