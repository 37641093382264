import React, { useContext } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import { CartContext } from "../Component/CartContext";
import { Link } from "react-router-dom";
import productData from "../Datas/productData";

function Main() {
  const { toCheck } = useContext(CartContext);
  const toCheckOut = (id, first, firstBot, name, price, time) => {
    toCheck(id, first, firstBot, name, price, time);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="clearFix" id="textArea">
          <h3>前売券</h3>
          <p>
            ※電子チケットのみの販売となります。以下のご注意事項をご確認の上ご購入ください。
          </p>
          <div className="notes">
            <p>
              <strong>＜ご注意事項＞</strong>
            </p>
            <ul className="indent mt-3 bold">
              <li>
                ・購入には、チケット購入サイト「Webket」への会員登録が必要となります。
              </li>
              <li className="red">
                ・
                <span className="wLine">
                  チケットを購入される前に必ず大会HPの
                  <a href="/" style={{ color: "#ff0000" }}>
                    アクセス
                  </a>
                  をご確認ください。
                </span>
              </li>
              <li className="red">
                ・購入後、いかなる場合でもチケットのキャンセルおよび変更はできません。
              </li>
              <li className="red">
                ・当日券の販売も行います。（お支払いは現金のみとなります。）
              </li>
              <li className="blue">
                ・同日2名様orお1人様で
                2日間ご利用の場合、2枚セット券が大変お得です。
              </li>
              <li className="blue">
                ・中学生以下は、保護者同伴に限り無料です。
              </li>
            </ul>
          </div>

          <ul className="inline align_c mt-3">
            <li className="view_timer" data-end-date="2024/2/13 10:00">
              <img
                src="https://www.all-kansai-golf.com/open/tickets/images/buy.png"
                alt="関西オープンゴルフ選手権チケット購入はこちら"
              />
            </li>
          </ul>

          <div className="clearFix">
            <div className="ticket01">
              <h4 id="price1">1日券（500円の軽食引換券付）</h4>
              <table>
                <tbody>
                  <tr>
                    <th>&nbsp;</th>
                    <th style={{ width: "40%" }} className="text-center">
                      日程
                    </th>
                    <th style={{ width: "20%" }} className="text-center">
                      金額
                    </th>
                    <th>&nbsp;</th>
                  </tr>
                  {productData
                    .filter((e) => e.id >= 1 && e.id <= 2)
                    .map((ticket) => (
                      <tr key={ticket.id}>
                        <td style={{ width: "10%" }}>
                          {ticket.first}
                          <br />
                          {ticket.firstBot}
                        </td>
                        <td style={{ width: "50%" }}>
                          <strong>{ticket.time}</strong>
                          <br />
                          {ticket.name}
                        </td>
                        <td style={{ width: "20%" }} className="price">
                          ￥{ticket.price}
                        </td>
                        <td style={{ width: "20%" }} className="price">
                          <Link
                            to="/checkout"
                            onClick={() =>
                              toCheckOut(
                                ticket.id,
                                ticket.first,
                                ticket.firstBot,
                                ticket.name,
                                ticket.price,
                                ticket.time
                              )
                            }
                          >
                            Shop
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <h4 id="price2">
                2枚セット券（500円の軽食引換券付、1日券が2枚）
              </h4>
              <div className="notes2">
                <p className="red">
                  <strong>
                    ※同日2名様orお1人様で 2日間ご利用の場合、大変お得です。
                  </strong>
                </p>
              </div>
              <table border="1" cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <th>&nbsp;</th>
                    <th className="text-center" style={{ width: "40%" }}>
                      日程
                    </th>
                    <th className="text-center" style={{ width: "20%" }}>
                      金額
                    </th>
                    <th>&nbsp;</th>
                  </tr>
                  {productData
                    .filter((e) => e.id >= 3 && e.id <= 4)
                    .map((ticket) => (
                      <tr key={ticket.id}>
                        <td style={{ width: "10%" }}>
                          {ticket.first}
                          <br />
                          {ticket.firstBot}
                        </td>
                        <td style={{ width: "50%" }}>
                          <strong>{ticket.time}</strong>
                          <br />
                          {ticket.name}
                        </td>
                        <td style={{ width: "20%" }} className="price">
                          ￥{ticket.price}
                        </td>
                        <td style={{ width: "20%" }} className="price">
                          <Link
                            to="/checkout"
                            onClick={() =>
                              toCheckOut(
                                ticket.id,
                                ticket.first,
                                ticket.firstBot,
                                ticket.name,
                                ticket.price,
                                ticket.time
                              )
                            }
                          >
                            Shop
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <h3>当日券</h3>
              <h4 id="price2">当日券（500円の軽食引換券付）</h4>
              <div className="notes2">
                <p>
                  <strong className="red">
                    ※ギャラリーゲートにて販売いたします。お支払いは現金のみとなります。
                  </strong>
                </p>
              </div>
              <table border="1" cellpadding="0" cellspacing="0">
                <tbody>
                  <tr>
                    <th>&nbsp;</th>
                    <th className="text-center" style={{ width: "40%" }}>
                      日程
                    </th>
                    <th className="text-center" style={{ width: "20%" }}>
                      金額
                    </th>
                    <th>&nbsp;</th>
                  </tr>
                  {productData
                    .filter((e) => e.id >= 5 && e.id <= 8)
                    .map((ticket) => (
                      <tr key={ticket.id}>
                        <td style={{ width: "10%" }}>
                          {ticket.first}
                          <br />
                          {ticket.firstBot}
                        </td>
                        <td style={{ width: "50%" }}>
                          <strong>{ticket.time}</strong>
                          <br />
                          {ticket.name}
                        </td>
                        <td style={{ width: "20%" }} className="price">
                          ￥{ticket.price}
                        </td>
                        <td style={{ width: "20%" }} className="price">
                          <Link
                            to="/checkout"
                            onClick={() =>
                              toCheckOut(
                                ticket.id,
                                ticket.first,
                                ticket.firstBot,
                                ticket.name,
                                ticket.price,
                                ticket.time
                              )
                            }
                          >
                            Shop
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
