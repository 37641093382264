import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div id="sponsor">
          <img
            alt=""
            src="	https://www.all-kansai-golf.com/open/images/banner_sponsor.png"
          />
        </div>
        <div className="footer_main">
          <div className="footer_bot17">
            <ul>
              <li>
                <Link to="/" _mstmutation="1" onClick={toTop}>
                  トップ
                </Link>
              </li>
              <li>
                <Link to="/contact" _mstmutation="1" onClick={toTop}>
                  お問い合わせ
                </Link>
              </li>
              <li>
                <Link to="/privacy" _mstmutation="1" onClick={toTop}>
                  プライバシーポリシー
                </Link>
              </li>
              <li>
                <Link to="/particular" _mstmutation="1" onClick={toTop}>
                  特定商取引法
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer_logo">
            <img
              src="	https://www.all-kansai-golf.com/open/images/footer_logo.png"
              alt="第87回関西オープンゴルフ選手権2022"
              width="298"
              height="159"
            />
          </div>
          <p>Copyright (C) 2016 関西ゴルフ連盟 All rights reserved. </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
