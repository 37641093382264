import "../css/header.css";
import React from "react";
import { Link } from "react-router-dom";

function Header() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="allDiv">
        <div className="headerDiv">
          <div className="header__center">
            <div className="leftImg">
              <img
                alt=""
                src="https://www.all-kansai-golf.com/images/logo.png"
              />
              <div>
                <img
                  alt=""
                  src="https://www.all-kansai-golf.com/images/kgosys.png"
                />
              </div>
            </div>
            <div className="header__right__img">
              <div className="header__top">
                <img
                  width="63"
                  height="16"
                  alt=""
                  src="	https://www.all-kansai-golf.com/images/fontsize.png"
                />
                <ul id="css_switch">
                  <li className="fixed">
                    <Link to="/" title="小">
                      小
                    </Link>
                  </li>
                  <li className="liquid">
                    <Link to="/" title="中">
                      中
                    </Link>
                  </li>
                  <li className="elastic">
                    <Link to="/" title="大">
                      大
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="header__mid" style={{ marginBottom: "1.5%" }}>
                <img
                  width="158"
                  height="20"
                  alt=""
                  src="https://www.all-kansai-golf.com/images/club.png"
                />
                <img
                  width="218"
                  height="20"
                  alt=""
                  src="https://www.all-kansai-golf.com/images/clause.png"
                />
              </div>
              <div className="header__bottom">
                <img
                  width="28"
                  height="28"
                  alt=""
                  src="https://www.all-kansai-golf.com/images/icon_Insta.png"
                />
                <img
                  width="28"
                  height="28"
                  style={{ marginRight: "2%" }}
                  alt=""
                  src="https://www.all-kansai-golf.com/images/icon_face.png"
                />
                <img
                  width="180"
                  height="28"
                  alt=""
                  src="https://www.all-kansai-golf.com/images/bk_button_memberlogin.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="header__sec_div">
          <div className="header__sec_img">
            <img alt="" src="/main/1001.jpg" />
          </div>
        </div>
        <div className="headerMid">
          <ul>
            <li>
              <Link to="/" className="index" id="aHome" onClick={toTop}>
                <font _mstmutation="1">HOME</font>
                <span>Home</span>
              </Link>
            </li>
            <li>
              <Link to="/privacy" className="event" id="aFesta" onClick={toTop}>
                <font _mstmutation="1">プライバシーポリシー</font>
                <span>Privacy Policy</span>
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="festa"
                id="aCharity"
                onClick={toTop}
              >
                <font _mstmutation="1">お問い合わせ</font>
                <span>Contact us</span>
              </Link>
            </li>
            <li>
              <Link to="/particular" className="kgu" id="aKgu" onClick={toTop}>
                <font _mstmutation="1">特定商取引法</font>
                <span>Specified Commercial Transactions Law</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
