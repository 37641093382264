const productData = [
  {
    id: 1,
    first: "予選",
    firstBot: "（平日券）",
    time: "5/16（木）・5/17（金）",
    name: "※5/16もしくは5/17の何れか1日にご利用いただけます。",
    price: "3300",
  },
  {
    id: 2,
    first: "決勝",
    firstBot: "（土日券）",
    time: "5/18（土）・5/19（日）",
    name: "※5/18もしくは5/19の何れか1日にご利用いただけます。",
    price: "4000",
  },
  {
    id: 3,
    first: "予選",
    firstBot: "（平日券）",
    time: "5/16（木）・5/17（金）",
    name: "※5/16もしくは5/17にご利用いただける1日券が2枚。",
    price: "5000",
  },
  {
    id: 4,
    first: "決勝",
    firstBot: "（土日券）",
    time: "5/18（土）・5/19（日）",
    name: "※5/18もしくは5/19にご利用いただける1日券が2枚。",
    price: "6000",
  },
  {
    id: 5,
    first: "予選",
    firstBot: "",
    time: "5/16（木）",
    name: "",
    price: "4000",
  },
  {
    id: 6,
    first: "予選",
    firstBot: "",
    time: "5/17（金）",
    name: "",
    price: "4000",
  },
  {
    id: 7,
    first: "決勝",
    firstBot: "",
    time: "5/18（土）",
    name: "",
    price: "5000",
  },
  {
    id: 8,
    first: "決勝",
    firstBot: "",
    time: "5/19（日）",
    name: "",
    price: "5000",
  },
];

export default productData;
